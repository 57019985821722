<template>
  <div class="fultServiceCenter-details">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="故障详情" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <div ref="pdfBoxRef">
          <!-- 表单 -->
          <ml-form disabled labelWidth="200px" style="width: 1200px; margin: 20px auto 40px">
            <!-- 提示标题 -->
            <el-divider content-position="left" style="font-weight: bold">故障详情信息</el-divider>
            <div class="globalTitle fultServiceCenter-details-globalTitle">
              故障详情
              <el-image
                class="fultServiceCenter-details-globalTitle-image"
                v-if="errorOrderStatusImg"
                :src="errorOrderStatusImg"
              />
            </div>
            <el-row>
              <el-col :span="12">
                <ml-select
                  prop="companyId"
                  placeholder="请选择阿米巴"
                  :options="unitOptions"
                  label="阿米巴:"
                  keyName="companyId"
                  labelName="companyName"
                  valueName="companyId"
                  v-model="faultServiceCenterForm.companyId"
                  @change="setCompany"
                />
              </el-col>
              <el-col :span="12">
                <!-- 所属项目 -->
                <ml-select
                  prop="projectId"
                  placeholder="请选择所属项目"
                  :options="projectData"
                  label="所属项目:"
                  keyName="projectId"
                  labelName="projectName"
                  valueName="projectId"
                  v-model="faultServiceCenterForm.projectId"
                />
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <!-- 设备 -->
                <ml-select
                  prop="deviceId"
                  placeholder="请选择设备"
                  :options="equipmentManageata"
                  label="设备:"
                  keyName="deviceId"
                  labelName="deviceName"
                  valueName="deviceId"
                  v-model="faultServiceCenterForm.deviceId"
                />
              </el-col>
              <el-col :span="12">
                <ml-input
                  prop="title"
                  label="标题:"
                  placeholder="请输入标题"
                  v-model.trim="faultServiceCenterForm.title"
                />
              </el-col>
            </el-row>

            <el-row>
              <!-- <el-col :span="12">
                <ml-select
                  prop="errorType"
                  placeholder="请选择类型"
                  :options="typeData"
                  label="类型:"
                  labelName="label"
                  valueName="label"
                  v-model="faultServiceCenterForm.errorType"
                />
              </el-col> -->
              <el-col :span="12">
                <ml-select
                  prop="severity"
                  placeholder="请选择严重程度"
                  :options="severityData"
                  label="严重程度:"
                  labelName="label"
                  valueName="label"
                  v-model="faultServiceCenterForm.severity"
                />
              </el-col>
            </el-row>

            <ml-input
              prop="address"
              label="地点:"
              placeholder="请输入地点"
              v-model.trim="faultServiceCenterForm.address"
            />

            <ml-input
              prop="errorDesc"
              label="故障描述:"
              placeholder="请输入故障描述"
              type="textarea"
              :rows="5"
              v-model.trim="faultServiceCenterForm.errorDesc"
            />
          </ml-form>

          <ml-form
            labelWidth="200px"
            style="width: 1200px; margin: 20px auto 40px"
            :model="faultServiceCenterForm"
          >
            <div class="globalTitle" v-if="faultServiceCenterForm.attachVos.length > 0">附件</div>
            <el-form-item label="附件:" v-if="faultServiceCenterForm.attachVos.length > 0">
              <ml-list
                fileName="fileName"
                :showDelete="false"
                :showDown="true"
                :list="faultServiceCenterForm.attachVos"
              />
            </el-form-item>

            <div class="globalTitle" v-if="faultServiceCenterForm.signName">签名</div>
            <el-form-item label="签名:" v-if="faultServiceCenterForm.signName">
              <el-image
                fit="scale-down"
                style="width: 100px; height: 100px"
                :src="`${BASEURL}${FILEPREVIEWURL}/${faultServiceCenterForm.signName}`"
                hide-on-click-modal
                :preview-src-list="[
                  `${BASEURL}${FILEPREVIEWURL}/${faultServiceCenterForm.signName}`
                ]"
              />
            </el-form-item>

            <div class="globalTitle" v-if="orderRepairRecordByError.partFlag === 'Y'">备品消耗</div>
            <ml-form
              v-if="orderRepairRecordByError.partFlag === 'Y'"
              labelWidth="200px"
              disabled
              style="width: 1200px; margin: 20px auto 40px"
              :model="orderRepairRecordByError"
            >
              <el-row>
                <el-col :span="12">
                  <ml-input
                    prop="partName"
                    placeholder="请输入消耗备品名称"
                    label="消耗备品名称:"
                    v-model="orderRepairRecordByError.partName"
                  />
                </el-col>
                <el-col :span="12">
                  <ml-input-number
                    prop="partCount"
                    placeholder="请输入数量"
                    label="数量:"
                    :min="0"
                    v-model="orderRepairRecordByError.partCount"
                  />
                </el-col>
              </el-row>
            </ml-form>

            <div class="globalTitle" v-if="deviceErrorLogsByError.length > 0">流程进度</div>
            <el-form-item v-if="deviceErrorLogsByError.length > 0" style="margin-bottom: 60px">
              <process-schedule
                disabled
                v-for="(item, index) in deviceErrorLogsByError"
                :key="item"
                :item="item"
                :signName="item.signName ? `${BASEURL}${FILEPREVIEWURL}/${item.signName}` : ''"
                :state="
                  errorStatus === '3'
                    ? index === deviceErrorLogsByError.length - 1
                    : index === deviceErrorLogsByError.length - 2
                "
              />
            </el-form-item>

            <!-- 处理故障按钮组 -->
            <ml-button
              submitText="指派"
              submitIcon="el-icon-thumb"
              cancelIcon="el-icon-star-off"
              cancelText="办结"
              cancelType="success"
              native-type="button"
              :showSubmit="faultServiceCenterForm.isReportOrder === 'Y'"
              :showCancel="faultServiceCenterForm.isReportComplete === 'Y'"
              @click-submit="clickAssignedDialogRefOrThrough"
              @click-cancel="clickErrorRecordComplete('Y')"
            >
              <el-button
                v-if="faultServiceCenterForm.isReportSubmit === 'Y'"
                size="medium"
                icon="el-icon-upload2"
                type="primary"
                @click="clickReportOrder"
              >
                提交报告
              </el-button>

              <el-button
                v-if="faultServiceCenterForm.isOrderBack === 'Y'"
                size="medium"
                icon="el-icon-refresh"
                type="danger"
                @click="clickErrorRecordComplete('B')"
              >
                退回工单
              </el-button>
              <el-button
                v-if="faultServiceCenterForm.isReportBack === 'Y'"
                size="medium"
                icon="el-icon-refresh"
                type="danger"
                @click="clickErrorRecordComplete('N')"
              >
                退回
              </el-button>
              <el-button
                size="medium"
                icon="el-icon-circle-close"
                @click="clickCancelFultServiceCenter"
              >
                返回上一页
              </el-button>
              <el-button
                size="medium"
                icon="el-icon-download"
                type="primary"
                @click="clickDownloadExcel"
              >
                导出
              </el-button>
            </ml-button>
          </ml-form>
        </div>
      </el-scrollbar>
    </ml-tip>

    <!-- 指派提示框 -->
    <ml-dialog
      width="600px"
      ref="assignedDialogRef"
      title="指派"
      closeText="保存"
      defaultClose
      showClose
      @click-close="assignedDialogClose(true)"
      @click-submit="assignedDialogClose"
    >
      <template #body>
        <ml-form
          labelWidth="120px"
          style="width: 100%"
          :model="assignedPeopleTableForm"
          :rules="assignedPeopleTableFormRule"
          ref="assignedPeopleTableFormRef"
        >
          <el-form-item label="指派维修工:">
            <el-radio-group v-model="assignedPeopleTableForm.orderType">
              <el-radio :label="0">所属单位</el-radio>
              <el-radio :label="1">其它单位</el-radio>
            </el-radio-group>
          </el-form-item>

          <amiba-aanager
            :orderType="assignedPeopleTableForm.orderType"
            :multiple="Number(assignedPeopleTableForm.orderType) === 0"
            v-model="repairUsersData"
            v-model:node="repairUsersNode"
          />
          <!-- <el-row>
            <el-col :span="20">
              <el-form-item label="指派维修工:" prop="repairUsers">
                <div class="name-tag-box">
                  <el-tag
                    size="mini"
                    closable
                    type="info"
                    v-for="(item, index) in assignedPeopleTableForm.repairUsers"
                    :key="item"
                    @close="clickTagClose(index)"
                  >
                    {{ item.userName }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div style="padding: 0 10px">
                <el-button type="text" @click="clickAssignedPeopleAdd">添加</el-button>
              </div>
            </el-col>
          </el-row> -->
          <ml-date-picker
            prop="endTime"
            placeholder="请选择预计完成时间"
            label="预计完成时间:"
            type="datetime"
            v-model="assignedPeopleTableForm.endTime"
          />
          <ml-select
            prop="processId"
            placeholder="请选择工单流程"
            :options="processOption"
            label="工单流程:"
            keyName="processId"
            labelName="processName"
            valueName="processId"
            v-model="assignedPeopleTableForm.processId"
          />
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 添加指派人提示框 -->
    <ml-dialog
      width="1200px"
      ref="assignedPeopleDialogRef"
      title="指派人"
      @clickSubmit="clickSubmitAssignedPeople"
      @clickClose="clickSubmitAssignedPeopleClose"
    >
      <template #body>
        <div>
          <div>
            <ml-form
              style="width: 1160px"
              inline
              labelWidth="80px"
              :model="assignedPeopleForm"
              ref="assignedPeopleFormRef"
            >
              <!-- 帐号 -->
              <ml-input
                prop="userCodeLike"
                placeholder="请输入账号"
                :input-style="{ width: '120px' }"
                label="帐号:"
                v-model="assignedPeopleForm.userCodeLike"
              />
              <!-- 用户 -->
              <ml-input
                prop="userNameLike"
                placeholder="请输入用户名"
                :input-style="{ width: '120px', marginRight: '40px' }"
                label="用户名:"
                v-model="assignedPeopleForm.userNameLike"
              />
              <!-- 搜索按钮 -->
              <ml-button
                submitText="搜索"
                submitIcon="el-icon-search"
                cancelText="重置"
                cancelIcon="el-icon-refresh"
                cancelType="danger"
                @click-cancel="resetSearchData"
                @click-submit="searchFn"
              />
            </ml-form>
          </div>
          <div class="assignedPeople-table-box">
            <el-table
              :data="tabberData.data"
              style="width: 100%"
              :height="200"
              @select-all="selectAll"
              @select="select"
              :row-key="row => row.userId"
              ref="assignedPeopleTableRef"
            >
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <!-- <el-table-column align="center" type="index" width="80">
                <template #default="scope">
                  {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
                </template>
              </el-table-column> -->
              <el-table-column align="center" label="账号" prop="userCode" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="用户名"
                prop="userName"
                min-width="15%"
              />
              <el-table-column align="center" label="阿米巴" prop="companyName" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="职位"
                prop="job"
                min-width="20%"
              />
            </el-table>
            <!-- 分页 -->
            <ml-pagination
              :total="tabberData.total"
              :page="tabberData.page"
              v-model="tabberData.page"
              :size="tabberData.size"
              @current-change="currentChange"
              @size-change="sizeChange"
            />
          </div>
        </div>
      </template>
    </ml-dialog>
    <!-- 办结提示框 -->
    <ml-dialog
      width="600px"
      ref="errorRecordCompleteDialogRef"
      :title="errorRecordCompleteFormData.title"
      @click-submit="errorRecordCompleteDialogSubmit"
      @click-close="errorRecordCompleteDialogClose"
    >
      <template #body>
        <ml-form
          labelWidth="120px"
          style="width: 95%"
          :model="errorRecordCompleteForm"
          :rules="errorRecordCompleteFormRule"
          ref="errorRecordCompleteFormRef"
        >
          <!-- 办结描述 -->
          <ml-input
            prop="content"
            :placeholder="`请输入${errorRecordCompleteFormData.title}描述`"
            :label="`${errorRecordCompleteFormData.title}描述`"
            type="textarea"
            :rows="5"
            v-model="errorRecordCompleteForm.content"
          />

          <el-form-item>
            <ml-list fileName="fileName" :list="errorRecordCompleteForm.attachVos" />
          </el-form-item>

          <ml-button :showSubmit="false" :showCancel="false">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>
          </ml-button>
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 提交报告提示框 -->
    <ml-dialog
      width="600px"
      ref="submitReportDialogRef"
      title="提交报告"
      @click-submit="submitReportDialogSubmit"
    >
      <template #body>
        <ml-form
          labelWidth="100px"
          style="width: 95%"
          :model="submitReportForm"
          :rules="submitReportFormRule"
          ref="submitReportFormRef"
        >
          <!-- 办结描述 -->
          <ml-date-picker
            prop="startTime"
            placeholder="请选择开始时间"
            label="开始时间:"
            type="datetime"
            v-model="submitReportForm.startTime"
          />
          <ml-date-picker
            prop="endTime"
            placeholder="请选择结束时间"
            label="结束时间:"
            type="datetime"
            v-model="submitReportForm.endTime"
          />
          <ml-input
            prop="content"
            placeholder="请输入内容描述"
            label="内容描述"
            type="textarea"
            :rows="5"
            v-model="submitReportForm.content"
          />

          <el-form-item>
            <ml-list fileName="fileName" :list="submitReportForm.attachVos" />
          </el-form-item>

          <ml-button :showSubmit="false" :showCancel="false">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSubmitReportSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>
          </ml-button>

          <el-form-item label="备品备件消耗">
            <el-radio-group v-model="submitReportForm.partFlag">
              <el-radio label="N">无</el-radio>
              <el-radio label="Y">有</el-radio>
            </el-radio-group>
          </el-form-item>

          <ml-select
            v-if="submitReportForm.partFlag === 'Y'"
            prop="partId"
            placeholder="请选择备品备件"
            :options="partOptions"
            label="备品备件:"
            keyName="partId"
            labelName="partName"
            valueName="partId"
            v-model="submitReportForm.partId"
          />

          <ml-input-number
            v-if="submitReportForm.partFlag === 'Y'"
            prop="partCount"
            placeholder="请输入数量"
            :min="0"
            :precision="0"
            label="数量"
            v-model="submitReportForm.partCount"
          />
        </ml-form>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getTabberData, tabberHeight, searchParams, formetData } from '@/utils'
import ProcessSchedule from '@components/processSchedule.vue'
import amibaAanager from '../../components/amibaAanager.vue'
import { BASEURL, FILEUPLOADURL, FILEPREVIEWURL } from '@API'
import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'

// 待审核
import isAuditImg from '../../assets/image/is-audit.png'
// 待处理
import isDealImg from '../../assets/image/is-deal.png'
// 已办结
import completeImg from '../../assets/image/complete.png'
// 已完成
import completeIng from '../../assets/image/completeIng.png'
// 已处理
import deal from '../../assets/image/deal.png'
// 处理中
import dealIng from '../../assets/image/deal-ing.png'
// 未办结
import noComplete from '../../assets/image/no-complete.png'

export default {
  name: 'FultServiceCenterDetails',
  components: {
    ProcessSchedule,
    amibaAanager
  },
  setup() {
    const { commit, getters, dispatch } = useStore()
    const router = useRouter()
    const route = useRoute()
    const { errorId } = route.query
    // 待处理 （-1 已退回 0 已上报 ）处理中  1 已指派 2 待审核 已处理  3 已办结
    const errorStatus = ref('0')
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const toKen = computed(() => getters.getToken)
    /* 开始 表单 **********************************/

    // 表单配置
    // const faultServiceCenterFormParams = ref()
    const faultServiceCenterForm = reactive({
      companyId: '',
      projectId: '',
      deviceId: '',
      title: '',
      errorType: '',
      severity: '',
      address: '',
      errorDesc: '',
      attachVos: [],
      signName: '',
      deviceName: '',
      province: '',
      city: '',
      area: '',
      latitude: '',
      longitude: '',
      isAuditPass: '',
      isAuditRefuse: '',
      isOrderBack: '',
      isOrderEvaluate: '',
      isRepeatSubmit: '',
      isReportBack: '',
      isReportComplete: '',
      isReportOrder: '',
      isReportSubmit: '',
      showStatusText: ''
    })

    const errorOrderStatusImg = ref()
    watch(
      () => faultServiceCenterForm.showStatusText,
      newvalue => {
        if (newvalue) {
          switch (newvalue) {
            case '待审核':
              errorOrderStatusImg.value = isAuditImg
              break
            case '已办结':
              errorOrderStatusImg.value = completeImg
              break
            case '已完成':
              errorOrderStatusImg.value = completeIng
              break
            case '已处理':
              errorOrderStatusImg.value = deal
              break
            case '处理中':
              errorOrderStatusImg.value = dealIng
              break
            case '未办结':
              errorOrderStatusImg.value = noComplete
              break

            default:
              errorOrderStatusImg.value = isDealImg
              break
          }
        }
      }
    )

    console.log(`${BASEURL}${FILEPREVIEWURL}/${faultServiceCenterForm.signName}`);
    // 备件消耗
    const orderRepairRecordByError = reactive({
      partFlag: '',
      partName: '',
      partCount: 0
    })

    // 故障流程进度
    const deviceErrorLogsByError = ref([])
    // 类型下拉列表
    const typeData = ref([{ label: '故障' }, { label: '缺陷' }])
    // 严重程度下拉列表
    const severityData = ref([
      { label: '轻微' },
      { label: '一般' },
      { label: '严重' },
      { label: '灾难' }
    ])
    // 单位名下拉列表
    const unitOptions = ref([])
    // 所属项目下拉列表
    const projectData = ref([])
    // 工单流程列表
    const processOption = ref([])

    // 设备下拉列表
    const equipmentManageata = ref([])
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    const setCompany = () => {
      faultServiceCenterForm.projectId = ''
      faultServiceCenterForm.deviceId = ''
    }
    // 处理设备
    const getEquipmentManageata = async projectId => {
      const projectParams = {
        projectId,
        companyId: faultServiceCenterForm.companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetDeviceInfos', projectParams)
      equipmentManageata.value = data || []
    }
    watch(
      () => faultServiceCenterForm.projectId,
      newvalue => {
        getEquipmentManageata(newvalue)
      }
    )
    watch(
      () => faultServiceCenterForm.companyId,
      newvalue => {
        getProjectData(newvalue)
      }
    )
    /** 指派人 开始************************************/
    // 打开指派或通过对话框
    const assignedPeopleTableFormRef = ref()
    const assignedPeopleTableForm = reactive({
      repairUsers: [],
      endTime: '',
      processId: '',
      errorId: '',
      orderType: ''
    })
    const assignedPeopleTableFormRule = {
      repairUsers: [{ required: true, message: '请选择指派人 ', trigger: 'blur' }],
      endTime: [{ required: true, message: '请选择预计完成时间 ', trigger: 'blur' }]
    }
    const assignedPeopleTableRef = ref()
    const assignedDialogRef = ref()
    // 打开指派对话框
    const clickAssignedDialogRefOrThrough = () => {
      if (['-1', '0'].includes(errorStatus.value)) {
        getOrderDraftFn()
        assignedDialogRef.value.showDialog = true
      }
    }
    // 保存，确认指派
    const assignedDialogClose = save => {
      const actionName = save ? 'fetchAddOrderDraft' : 'fetchAddCreateErrorOrder'

      assignedPeopleTableFormRef.value.CustomFormRef.validate()
        .then(() => {
          const faultServiceCenterParams = searchParams(assignedPeopleTableForm, ['endTime'])
          if (assignedPeopleTableForm.endTime) {
            faultServiceCenterParams.endTime = formetData(assignedPeopleTableForm.endTime)
          }
          faultServiceCenterParams.errorId = errorId
          dispatch(actionName, faultServiceCenterParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              router.push({ path: '/faultServiceCenter' })
            }
          })
        })
        .catch(() => {})
    }
    // 打开指派人对话框
    const assignedPeopleDialogRef = ref()
    const clickAssignedPeopleAdd = () => {
      selectData.value = saveSelectData.value
      getAssignedPeopleData()
      assignedPeopleDialogRef.value.showDialog = true
    }
    // 指派人表单
    const assignedPeopleFormRef = ref()
    // 指派人搜索条件
    const assignedPeopleForm = reactive({
      userCodeLike: '',
      userNameLike: '',
      companyId: '',
      projectId: ''
    })
    // 指派人单位配置
    const assignedPeopleProjectData = ref([])
    const getAssignedPeopleProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      assignedPeopleProjectData.value = data || []
    }

    // 监听指派人单位
    watch(
      () => assignedPeopleForm.companyId,
      newvalue => {
        getAssignedPeopleProjectData(newvalue)
      }
    )
    // 删除Tag
    const clickTagClose = index => {
      saveSelectData.value.splice(index, 1)
      selectData.value = []
      assignedPeopleTableRef.value.clearSelection()
    }

    // 查看指派草稿
    const getOrderDraftFn = () => {
      dispatch('fetchGetOrderDraft', { errorId }).then(data => {
        if (data && data.code === 200 && data.data) {
          assignedPeopleTableForm.orderType = data.data.orderType || 0
          assignedPeopleTableForm.endTime = data.data.endTime || ''
          assignedPeopleTableForm.errorId = data.data.errorId || ''
          assignedPeopleTableForm.processId = data.data.processId || ''
          nextTick(() => {
            saveSelectData.value = data.data.repairUserVos || []
            repairUsersNode.value = data.data.repairUserVos || []
          })
        }
      })
    }

    // 查询工单审核流程
    const getOrderProcesss = () => {
      dispatch('fetchGetOrderProcesss', { errorId }).then(data => {
        if (data && data.code === 200 && data.data) {
          processOption.value = data.data || []
        }
      })
    }

    /** 指派人 结束************************************/
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10,
      saveSelectData: [],
      isSubmie: false
    })
    // 选择指派人列表
    const selectData = ref()
    // 保存选择人
    const saveSelectData = ref([])

    watch(
      () => tabberData.saveSelectData,
      newvalue => {
        saveSelectData.value = newvalue
      }
    )
    // 获取账户列表
    const getAssignedPeopleData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(assignedPeopleForm, ['companyId', 'projectId'])
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetGanagerEngineers',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
      if (selectData.value.length > 0) {
        selectData.value.map(item => {
          assignedPeopleTableRef.value.toggleRowSelection(item, true)
        })
      }
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getAssignedPeopleData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 重置搜索内容
    const resetSearchData = () => {
      assignedPeopleFormRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getAssignedPeopleData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getAssignedPeopleData() : (tabberData.page = 1)
    }

    // 选中的添加到列表
    const getSelection = selection =>
      selection.map(item => {
        return {
          userId: item.userId,
          userName: item.userName,
          companyId: item.companyId
        }
      })
    // 选中单个
    const select = selection => {
      selectData.value = getSelection(selection)
    }
    // 选中全部
    const selectAll = selection => {
      selectData.value = selectData.value = getSelection(selection)
    }

    // 提交选中指派人
    const clickSubmitAssignedPeople = () => {
      tabberData.isSubmie = true
      saveSelectData.value = selectData.value
      repairUsersData.value = selectData.value
      assignedPeopleDialogRef.value.showDialog = false
    }
    // 取消选择指派人
    const clickSubmitAssignedPeopleClose = () => {
      if (!tabberData.isSubmie) {
        selectData.value = []
        assignedPeopleTableRef.value.clearSelection()
      }
      tabberData.isSubmie = false
    }

    /* 结束 数据列表 **********************************/

    // 取消表单
    const clickCancelFultServiceCenter = () => {
      router.go(-1)
      // router.push({ path: '/faultServiceCenter' })
    }
    // 获取单个故障信息
    const getFaultServiceCenterDetails = async () => {
      // 查看故障详情
      await dispatch('fetchGetDeviceErrorRecord', errorId).then(data => {
        if (data && data.code === 200 && data.data) {
          errorStatus.value = data.data.errorStatus
          // 绑定阿米巴和项目ID
          assignedPeopleForm.companyId = data.data.companyId
          assignedPeopleForm.projectId = data.data.projectId

          Object.keys(faultServiceCenterForm).map(item => {
            if (['attachVos'].includes(item)) {
              faultServiceCenterForm[item] = data.data[item] || []
            } else {
              faultServiceCenterForm[item] = data.data[item]
            }
          })
        }
      })
      // 查询故障维修报告
      await dispatch('fetchGetOrderRepairRecordByError', { errorId }).then(data => {
        if (data && data.code === 200 && data.data) {
          Object.keys(orderRepairRecordByError).map(item => {
            orderRepairRecordByError[item] = data.data[item]
          })
        }
      })
      // 查询故障流程进度
      await dispatch('fatchGetDeviceErrorLogsByError', { errorId }).then(data => {
        if (data && data.code === 200 && data.data) {
          deviceErrorLogsByError.value = data.data
        }
      })
    }

    /* 结束 用户表单 **********************************/

    // 对话框 el
    const errorRecordCompleteDialogRef = ref()
    // 表单 el
    const errorRecordCompleteFormRef = ref()
    const errorRecordCompleteFormData = reactive({
      title: ''
    })
    const errorRecordCompleteForm = reactive({
      content: '',
      attachVos: []
    })
    // 点击办结按钮
    const clickErrorRecordComplete = isComplete => {
      errorRecordCompleteFormData.title =
        isComplete === 'Y' ? '办结' : isComplete === 'N' ? '退回' : '退回工单'
      errorRecordCompleteDialogRef.value.showDialog = true
    }
    // 校验
    const errorRecordCompleteFormRule = {
      content: [{ required: true, message: '请输入描述', trigger: 'blur' }]
    }
    // 办洁，退回确认对证框
    const errorRecordCompleteDialogSubmit = () => {
      const title = errorRecordCompleteFormData.title
      const actionName =
        title === '办结'
          ? 'fetchAddErrorRecordComplete'
          : title === '退回'
          ? 'fetchAddErrorRecordBack'
          : 'fetchErrorOrderBack'
      const nParams = searchParams(errorRecordCompleteForm)
      nParams.errorId = errorId
      errorRecordCompleteFormRef.value.CustomFormRef.validate()
        .then(() => {
          dispatch(actionName, nParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })

              errorRecordCompleteDialogRef.value.showDialog = false
              clickCancelFultServiceCenter()
            }
          })
        })
        .catch(() => {})
    }
    // 取消对证框
    const errorRecordCompleteDialogClose = () => {
      errorRecordCompleteForm.attachVos = []
    }

    // 上传附件
    const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    const onSuccess = files => {
      if (files.data) {
        errorRecordCompleteForm.attachVos = [...errorRecordCompleteForm.attachVos, ...files.data]
      }
    }
    const repairUsersData = ref([])
    const repairUsersNode = ref([])
    watch(
      () => repairUsersNode.value,
      newvalue => {
        let nList = []
        let nIDs = []
        newvalue.map(item => {
          if (item.children) {
            item.children.map(v => {
              nList.push(v)
              nIDs.push(item.userId)
              nIDs.push(v.userId)
            })
          } else {
            nList.push({
              userName: item.userName,
              userId: item.userId,
              companyId: item.companyId
            })
            nIDs.push([item.userId])
          }
        })
        repairUsersData.value = [...nIDs]

        assignedPeopleTableForm.repairUsers = nList
      }
    )

    // 提交报告
    const submitReportDialogRef = ref()
    const submitReportFormRef = ref()
    const partOptions = ref([])
    const submitReportForm = reactive({
      startTime: '',
      endTime: '',
      content: '',
      attachVos: [],
      partFlag: 'N',
      partId: '',
      partCount: 0
    })
    // 校验
    const isPartFlagY = () => submitReportForm.partFlag === 'Y' && !!submitReportForm.partId
    const submitReportFormRule = {
      startTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
      endTime: [{ required: true, message: '请选择结束时间', trigger: 'blur' }],
      content: [{ required: true, message: '请输入描述', trigger: 'blur' }],
      partFlag: [{ required: true, message: '请选择是否有备品备件', trigger: 'blur' }],
      partId: [{ validator: isPartFlagY, message: '请选择备品备件', trigger: 'blur' }]
    }
    watch(
      () => submitReportForm.partFlag,
      newvalue => {
        if (newvalue === 'N') {
          submitReportForm.partId = ''
          submitReportForm.partCount = 0
        }
      }
    )
    // 上传附件
    const onSubmitReportSuccess = files => {
      if (files.data) {
        submitReportForm.attachVos = [...submitReportForm.attachVos, ...files.data]
      }
    }
    const clickReportOrder = () => {
      submitReportDialogRef.value.showDialog = true
    }
    // 提交报告
    const submitReportDialogSubmit = () => {
      submitReportFormRef.value.CustomFormRef.validate()
        .then(() => {
          const nParams = searchParams(submitReportForm, ['startTime', 'endTime', 'partId'], true)
          if (submitReportForm.partFlag === 'Y') {
            nParams.partId = submitReportForm.partId
          }
          if (submitReportForm.startTime) {
            nParams.startTime = formetData(submitReportForm.startTime)
          }
          if (submitReportForm.endTime) {
            nParams.endTime = formetData(submitReportForm.endTime)
          }
          nParams.errorId = errorId
          dispatch('fetchAddOrderRepairRecord', nParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              router.push({ path: '/faultServiceCenter' })
            }
          })
        })
        .catch(() => {})
    }

    // 导出 Excel
    const pdfBoxRef = ref()
    const clickDownloadExcel = () => {
      nextTick(() => {
        const w = pdfBoxRef.value.offsetWidth
        const h = pdfBoxRef.value.offsetHeight + 60
        html2canvas(pdfBoxRef.value, {
          useCORS: true,
          y: -100,
          x: (w - 1660) / 2,
          width: 1600,
          height: h
        }).then(canvas => {
          const contentW = canvas.width
          const contentH = canvas.height
          // 每页的高度
          const pageH = (contentW / 592.25) * 841.89
          // 款生成PDF的html高度
          let leftH = contentH
          // 页面偏移
          let position = 0
          // A4 尺寸
          const imgW = 595.28
          const imgH = (592.28 / contentW) * contentH
          //返回图片URL，参数：图片格式和清晰度(0-1)
          const pageData = canvas.toDataURL('image/png', 1.0)
          const pdf = new jsPdf('', 'pt', 'a4', true)

          // //方向默认竖直，尺寸ponits，格式a4【595.28,841.89]
          setTimeout(() => {
            // //需要dataUrl格式
            if (leftH < pageH) {
              pdf.addImage(pageData, 'png', 0, 0, imgW, imgH)
            } else {
              while (leftH > 0) {
                pdf.addImage(pageData, 'png', 0, position, imgW, imgH)
                leftH -= pageH
                position -= 841.89
                // 避免添加空白页面
                if (leftH > 0) {
                  pdf.addImage()
                }
              }
            }
            pdf.save(`${faultServiceCenterForm.title || '故障详情'}.pdf`)
          }, 600)
        })
      })
    }

    /** 办结 开始***********************************/
    /** 办结 结束************************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
      // 获取单位名
      const sparePartsData = await getTabberData(dispatch, 'fetchGetspareParts', {
        companyId: faultServiceCenterForm.companyId
      })
      partOptions.value = sparePartsData.data || []

      getOrderProcesss()
      // 获取单个故障信息
      await getFaultServiceCenterDetails()
    })
    return {
      pdfBoxRef,
      clickDownloadExcel,
      faultServiceCenterForm,
      unitOptions,
      projectData,
      clickAssignedDialogRefOrThrough,
      clickCancelFultServiceCenter,
      tabberHeight,
      setCompany,
      equipmentManageata,
      typeData,
      severityData,
      toKen,
      errorStatus,
      assignedDialogRef,
      assignedPeopleDialogRef,
      clickAssignedPeopleAdd,
      assignedPeopleForm,
      assignedPeopleProjectData,
      tabberData,
      currentChange,
      sizeChange,
      select,
      selectAll,
      resetSearchData,
      searchFn,
      assignedPeopleFormRef,
      assignedPeopleTableRef,
      clickSubmitAssignedPeople,
      clickSubmitAssignedPeopleClose,
      saveSelectData,
      selectData,
      clickTagClose,
      assignedPeopleTableFormRef,
      assignedPeopleTableForm,
      assignedPeopleTableFormRule,
      assignedDialogClose,
      errorRecordCompleteDialogRef,
      errorRecordCompleteDialogSubmit,
      clickErrorRecordComplete,
      errorRecordCompleteFormRef,
      errorRecordCompleteForm,
      errorRecordCompleteFormRule,
      action,
      onSuccess,
      errorRecordCompleteDialogClose,
      errorRecordCompleteFormData,
      orderRepairRecordByError,
      deviceErrorLogsByError,
      processOption,
      FILEPREVIEWURL,
      BASEURL,
      repairUsersData,
      repairUsersNode,
      clickReportOrder,
      submitReportDialogRef,
      submitReportFormRef,
      submitReportForm,
      submitReportDialogSubmit,
      submitReportFormRule,
      partOptions,
      onSubmitReportSuccess,
      errorOrderStatusImg
    }
  }
}
</script>

<style lang="scss">
.fultServiceCenter-details {
  position: relative;
  &-globalTitle {
    position: relative;
    z-index: 1;
    &-image {
      position: absolute;
      width: 100px;
      top: -50px;
      left: 150px;
    }
  }
}

.name-tag-box {
  border: 1px solid $--border-color-base;
  border-radius: 4px;
  padding: 0 5px;
  min-height: 40px;
  max-height: 110px;
  overflow-x: hidden;
  .el-tag {
    margin-right: 5px;
  }
}
.assignedPeople-select {
  .el-input__inner {
    width: 150px;
  }
}

.assignedPeople-table-box {
  border-top: 1px solid $--border-color-lighter;
  padding-top: 10px;
}
</style>
